export const regexPattern = {
    ALLOW_LETTERS_NUMBERS_SPACES: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
    ALLOW_IFSC : /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
    ALLOW_PAN: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
    ALLOW_EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*\.[a-zA-Z]{2,5}$/,
    ALLOW_BLOGS_SLUG: /^[a-zA-Z0-9-\s]*$/,
    VALID_URL: /^https?:\/\/(?:www\.)?[^\s\/$.?#].[^\s]*$/,
    DEMAT_PATTEREN: /^([Ii][Nn]|\d{2})\d{14}$/,
    ALLOW_NUMBERS: /^[0-9]+$/
};
